export const resource = [
  { "q" : "自學師資怎麼找？", "lev" : 2,
    "c" : "資源",
    "as" : [
      "你可以就近找認識的親朋好友，也可以尋求學校或各方的師資，當然也可以活用書本與網路資源。",
      "在計畫書中列舉的師資，不一定要有教師證。",
      "任何相關領域的工作經驗，都可以當作證明。",
      "因此，我們對「老師」的想像，可以放到一個更大的範圍。"
    ],
    "es": []
  },
  { "q" : "自學會不會很花錢？", "lev" : 0,
    "c" : "資源",
    "as" : [
      "不一定。因每個家庭的計畫而異噢。",
      "你可使用免費學習資源，像是公立圖書館、博物館、網路教材，也可參與面對面或是網路上的學習社群。",
      "也可以用[換工]的方式來替代[學費]，或是在願意栽培新人技藝的工作室當[學徒]。",
      "在社群中，多和有經驗的自學家庭打聽，可以瞭解更多資源喔！"
    ],
    "es": []
  },
  { "q" : "自學有沒有政府的教育經費？", "lev" : 1,
    "c" : "資源",
    "as" : [
      "現況(2024)是：",
      "1. 中小學階段，自學生的教育單位成本，全部撥給設籍學校。",
      "2. 高中職階段，自學有一些補助。",
      "最新方案請詢問教育局的承辦人員。"
    ]
  },
  { "q" : "邊鄉部落可不可以自學？", "lev" : 1,
    "c" : "資源",
    "as" : ["事實上有部落自學的先例，透過互助合作、耆老與家長協同傳承文化，部落自行辦學、發展共同經濟"],
    "es": [
      { "t": "八八風災後大社部落返鄉自學", "h": "http://titv.ipcf.org.tw/news-6901"},
      { "t": "司馬庫斯部落自學與共同經濟", "h": "http://www.meworks.net/meworksv2/meworks/page1.aspx?no=4073"}
    ]
  },
  { "q" : "自學安排打工賺錢好不好？", "lev" : 3,
    "c" : "資源",
    "as" : [
      "打工可以累積經驗",
      "不過，具有專業技術後的工作收入，可能比打工的收入，高出十倍以上。",
      "因此，學生時代著眼點還是放在學習上較佳。",
      "選擇志工或打工的地方時，也應優先考慮能夠學到什麼，而不是能賺多少錢。",
      "如果工作本身和你的興趣與專業發展一致，工作環境與時間又能讓你維持健康，那是最好的選擇。"
    ],
    "es": []
  }
] 
