export const support = [
  { "q" : "在家自學，怎麼交朋友？", "lev" : 0,
    "c" : "支持",
    "as" : [
      "全台灣目前有超過3000人申請在家自學",
      "大部份自學家庭，都會找到孩子接觸人際的機會，依照興趣和地緣相近處來參與社群。",
      "在家自學只是沒有每天上學，並不是一直待在家裡不出門。",
      "交友圈可能很多元，往往還能更長久，不會因為在學校的畢業或重新分班就被拆散。",
      "也可參與自學2.0網路互助地圖。"
    ],
    "es": [{
      "h": "http://we.alearn.org.tw/",
      "t": "自學2.0互助地圖"
    }]
  },
  { "q" : "我家附近有沒有人自學？", "lev" : 2,
    "c" : "支持",
    "as" : [
      "除了跟認識的朋友處打聽，你還可以在社交網站上尋找在地的自學社團。",
      "也可上[自學2.0互助地圖]登錄自己和尋找夥伴，彼此認識。"
    ],
    "es": [{
      "h": "http://we.alearn.org.tw/",
      "t": "自學2.0互助地圖"
    }]
  },
  { "q" : "如何跟家人彼此支持合作？", "lev" : 2,
    "c" : "支持",
    "as" : [
      "溝通是雙向的，",
      "要好好溝通，首先要先學會「聆聽」和「表達」。",
      "合作討論並不只是彼此對話，要有一些前提和準備，願意好好聴人說話，願意幫助對方把話說清楚。",
      "好好的聽人把話說清楚，不急著回應、不急著評斷或是反駁，這也是需要練習的事。",
      "表達的時候，直接清楚，理直、氣和，也是需要練習的事。"
    ],
    "es" : [
    {
      "t": "合作討論文化",
      "h": "https://sites.google.com/site/alearn13994229/a-03/he-zuo-tao-lun-wen-hua"
    }]
  },
  { "q" : "如何得到父母的支持？", "lev" : 1,
    "c" : "支持",
    "as" : [
      "學齡階段的自學，需要父母的支持和簽字。",
      "如果父母一開始不支持，一定有他們的擔心和顧慮。",
      "你瞭解那些顧慮是什麼嗎？",
      "溝通是雙向的，",
      "要讓他們瞭解你的處境與需求，也要瞭解父母在擔心什麼。",
      "這樣才可能逐步化解父母的擔心，轉變為支持力量。"
    ],
    "es" : []
  }
]