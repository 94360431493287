export const start = [
  { "q" : "不上學，是否合法？", "lev" : 0,
    "c" : "起步",          
    "as" : [
          "教育基本法保障家長的教育選擇權和孩子的受教權。",
          "經過多年努力，台灣非學校教育(通稱自學)已合法，",
          "法規為〈高級中等以下教育階段非學校型態實驗教育實施條例〉(103年11月19日總統華總一義字第10300173311號令公布)，",
          "目前比日本、中國、香港、韓國，對自學都更友善、更開放。",
          "只要經過申請的手續，和過程的評鑑，就可以自訂學習計畫，不必上學。"
    ],
    "es" : [
      { "t": "體制外學校、在家自學有法源了",
        "h": "https://video.udn.com/news/225102"
      },
      { "t": "做個堂堂正正的「不在校上學」學生", 
        "h": "http://www.homeschool.tw/2014/10/blog-post.html"
      },
      { "t": "自學法制化歷史",
        "h": "https://sites.google.com/view/autoalearn/%E8%87%AA%E5%AD%B8%E6%B3%95%E5%88%B6%E5%8C%96"
      }
    ]
  },
  { "q" : "自學和中輟有何不同？", "lev" : 0,
    "c" : "起步",          
    "as" : [
          "合法申請自學，有學籍、有文憑。",
          "教育局會定時考查你的自學計畫與成果。",
          "有審議與成果發表，維持你和教育局的關係。",
          "中輟的話就沒有學籍、文憑、固定和教育局的關係。"
    ],
    "es" : []
  },
  { "q" : "如何提出自學申請？", "lev" : 1,
    "c" : "起步",
    "as" : [
      "在台灣要自學，要申請「非學校型態實驗教育」，你可以依年級，戶籍地，要不要和學校合作這三項思考，搜尋你們戶籍所在地教育局、非學校型態教育的網站，下載申請的工具包或檔案，依照說明，完成實驗計畫，在時間內送件即可。",
      "送件一年有兩次，上學期要在4.30日前送件，下學期申請，在10/31日前送件。如果有急迫需求，送件前可以先用請假的方式。",
      "各縣市公告的「實驗教育計畫」僅供參考。家庭可自行設計，不一定要完全按照公告的格式。",
      "如果是高中職自學，有兩種選擇，一種學籍掛教育局，一種要和學校談合作計畫，兩種各有利弊，要和小孩討論。",
      "另外，我們鼓勵高中職生自己寫自己的計畫，因為高中職生年紀大了，有能力自己寫。",
      "申請完成之後，每學年結束後兩個月內要繳交自學成果報告書給縣市教育局。",
      "進一步資訊，可參考「保障教育選擇權聯盟」網站的「一週完成自學申請書」。"
    ],
    "es": [
      { "t": "一週完成自學申請書", h:"https://www.homeschool.tw/p/blog-page.html"},
      { "t": "申請書下載與各縣市承辦人", "h": "http://www.homeschool.tw/p/02-272088896371-edupe.html"}
    ]
  },
  { "q" : "為什麼自學要有審議？", "lev" : 1,
    "c" : "起步",
    "as" : [
      "「教育基本法」同時保障家長的「教育選擇權」和孩子的「受教權」，在實行條例中明定和「補習教育」不同。",
      "教育選擇權不能無限擴大，需要機制來保障孩子的受教權。",
      "在更好的機制被研發出來之前，「審議制」是目前政府認為比較可行的方式。"
    ],
    "es": [
      { "t": "教育基本法", "h": "http://law.moj.gov.tw/LawClass/LawAll.aspx?PCode=H0020045"}
    ]
  },
  { "q" : "審議過程會有什麼問題？", "lev" : 2,
    "c" : "起步",
    "as" : [
      "1. 有可能你的計畫確實有不夠清楚之處。",
      "2. 若是對方的問題，你可以現場錄音，加上文字說明向教育局發信投訴。",
      "3. 亦可在自學社群（非學校論壇、臉書社團等）尋求支援與共同發聲"
    ],
    "es" : [{
      "t" : "理直、氣和",
      "h" : "https://www.facebook.com/trini.tsai/posts/1494566703896109"
    }]
  }
]
