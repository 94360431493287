export const plan = [{
    "q" : "如何撰寫計畫書？", "lev" : 2,
    "c" : "計畫",
    "as" : [
      "每個人不同，計畫當然也都不一樣。要去瞭解自己。",
      "你可以先下載教育局提供的申請書格式範本，當然這只是參考用。",
      "接下來可以用每月或是每學期為單位，對每個科目設訂學習目標，和檢視的方式。",
      "一開始自學，留白給興趣試探的時間也很重要。課表不必排滿，可以多留一些空白，彈性運用。",
      "把做計畫當做是一個瞭解自己的過程，不必一直煩惱怎麼寫才會過。",
      "真實的寫，通常就會過了。"
    ], 
    "es" : [
      {
        "t": "自學申請書格式範本下載(本例為台北市教育局，您需要在您的縣市教育局網站下載)",
        "h": "https://teecid.tp.edu.tw/Home/NonAnnDow"
      },
      { "t": "如何準備自學申請書？",
        "h": "https://flipedu.parenting.com.tw/article/5263"
      },
      {
        "t": "對家長撰寫在家自學計畫的十一點淺見",
        "h": "https://www.facebook.com/naturetang/posts/10203024582333230"
      }
    ]
  },
  { 
    "q" : "「玩」與「唸書」的平衡？", "lev" : 3,
    "c" : "計畫",
    "as" : [
      "「玩」與「唸書」可以相容並進。",
      "你中有我，我中有你。",
      "把「玩」的活動做到好，一定需要相關的學習；尋找自己合適的方法、自訂目標與進度的學習，也像是在玩一樣，有自主性和樂趣。"
    ]
  },
  { 
    "q" : "自學生要怎麼升學？", "lev" : 3,
    "c" : "計畫",
    "as" : [
      "國中學籍是掛在學校的，如果打算申請高中職，隨學校時間報名參加國中教育會考，依照多元入學管道申請高中職即可。",
      "高中階段完成自學證明的自學時間至少要有一年半，自學加上在學期間滿三年視同完成高中階段，可以報考學測，之後參加推甄、申請，或是報名指考、選填志願，也可考慮幾間大學開放的特殊選才入學管道，如：清華、交大等數十所學校，甚至不需要考試。需要注意的是目前高中自學生就算跟學校合作或掛學籍，都不能參加繁星計畫，因為涉及到在學成績的公平性。"
    ]
  },
  { "q" : "自學的時間要怎麼安排？", "lev" : 3,
    "c" : "計畫",
    "as" : [
      "離開學校後，健康規律的生活作息要靠自己和家庭來維持。",
      "學習和專業成長的部份，時間安排上，要能專注，不要太切割零散。",
      "時間安排沒有標準答案，但是每件事要能專注，需要足夠長的持續時間。",
      "同樣的，安排適度留白時間給自我探索也很重要。"
    ],
    "es" : [
      {
        "t": "自學週課表分析",
        "h": "http://pulse.naer.edu.tw/content.aspx?type=N&sid=207"
      }
    ]
  },
  { 
    "q" : "自學對未來出路是否有幫助？", "lev" : 1,
    "c" : "計畫",
    "as" : [
      "每家的方法不盡相同，很難一概而論。",
      "不過，因為自學可以量身打造，所以可視情況和能力，接觸專業社群，認識不同圈子",
      "並且，時間自行安排下，可以培養專注和實現中長程目標的習慣。",
      "能自訂目標並加以實現，對於未來的自主生活與工作能力都是好的。"
    ]
  },
  { 
    "q" : "是不是七大領域都要寫進計畫中？", "lev" : 3,
    "c" : "計畫",
    "as" : [
      "國中小階段，通常教育局會要求七大領域都要有。",
      "不過，你可以用自己的方法、自選的教材來學習",
      "也可以把學科與你的興趣統整起來。",
      "例如，假如你對音樂有興趣，可以學習音樂的歷史、樂理中的數學等等。",
      "自學不必照本宣科，所以還是可以保有相當大的彈性。"
    ]
  },
  { 
    "q" : "自學生需不需要面對考試？", "lev" : 1,
    "c" : "計畫",
    "as" : [
      "可以不必參加段考。",
      "如果不參加段考，你需要在計畫中列入其他的評量方式，讓人知道有沒有學會。",
      "例如，製作學習記錄檔案、辦成果發表、整理作品集、參加比賽競試或是志工等等。",
      "多元評量還是有所評量，只是不一定要用紙筆測驗的方式。"
    ],
    "es" : []
  },
  { "q" : "有可能每週上學三天、在家自學兩天？", "lev" : 1,
    "c" : "計畫",
    "as" : [
      "可以，這也是一種自學計畫",
      "你可以跟教育局申請自學，自學計畫將學校資源列入。",
      "同時也要跟學校老師溝通好。",
      "祝你和老師的溝通順暢。"
    ],
    "es": []
  },
  { "q" : "學校會不會改變？", "lev" : 0,
    "c" : "計畫",
    "as" : [
      "學校當然會改革，二幾年來一直在教改。108課綱以來，學校體制內也有多元的進步發展。",
      "每所學校、每個班級和老師都有各別差異，無法一概而論",
      "自學(非學校教育)旨在讓家庭有權利選擇自訂計畫，不是只能上學。",
      "當學校不是唯一的教育方式，家庭有自由流動的權利時，對於學校的改革進步也會有益。"
    ],
    "es": []
  }
]
